import { facebookIcon, linkedinIcon, twitterIcon, youtubeIcon } from '@/assets/images/icons';
import i18n from '@/services/localization';
import { PAGES } from '@/shared/consts';
import { RefObject } from 'react';

export const socialMedia = [
  { name: 'twitter', href: 'https://twitter.com/user1st', icon: twitterIcon },
  { name: 'facebook', href: 'https://www.facebook.com/user1st/', icon: facebookIcon },
  { name: 'linkedin', href: 'https://www.linkedin.com/company/user1st/', icon: linkedinIcon },
  {
    name: 'youtube',
    href: 'https://www.youtube.com/@user1stdigitalaccessibilit37',
    icon: youtubeIcon,
  },
];

export const getStatements = () => [
  { name: i18n.t('footer.accessibility_statement'), path: PAGES.SHARED.ACCESSIBILITY_STATEMENT },
  { name: i18n.t('footer.privacy_policy'), path: PAGES.SHARED.PRIVACY_POLICY },
  { name: i18n.t('footer.terms'), path: PAGES.SHARED.TERMS_AND_CONDITIONS },
];

export type FooterProps = {
  topPageRef: RefObject<HTMLDivElement>;
};
