import { U1Icon } from '@/assets/images/icons';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const DEFAULT = {
  TITLE: 'User1st Accessibility',
  DESCRIPTION:
    'User1st’s Suite offers an efficient path to accessibility compliance. Discover our digitally accessible solutions that suit your business needs best.',
  IMAGE_URL: U1Icon,
};

type MetaTagProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  route?: string;
  locale?: string;
  robotsTags?: string[];
  robots?: {
    index?: boolean;
    follow?: boolean;
  };
};

const BASE_ROBOT_TAGS = ['follow', 'index'];

const isClientSide = () => typeof window !== 'undefined';

const useWindowLocation = () => {
  if (!isClientSide()) {
    throw new Error("You're not in a client-side browser environment!");
  }

  return window.location;
};
export const MetaTags = ({
  title = DEFAULT.TITLE,
  description = DEFAULT.DESCRIPTION,
  imageUrl = DEFAULT.IMAGE_URL,
  locale,
  robotsTags = [],
}: MetaTagProps) => {
  const { pathname } = useLocation();
  const { origin } = useWindowLocation();

  const url = `${origin}${pathname}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="apple-mobile-web-app-title" content={title} />
      {/* <link rel="canonical" href="https://user1st.com/" /> */}

      {/* twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:url" content={url} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={imageUrl} />

      {/* og */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale ?? 'en-US'} />

      {/* robots */}
      <meta name="robots" content={(robotsTags.length ? robotsTags : BASE_ROBOT_TAGS).join(', ')} />
    </Helmet>
  );
};
